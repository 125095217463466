<template>
     <div class="accordion-item">
        <h2 class="accordion-header" >
            <button class="accordion-button" :class="[{'collapsed':!show}, linkClass]" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+id" aria-expanded="true" :aria-controls="id">
                <slot name="header">{{title}}</slot>
            </button>
        </h2>
        <div :id="id" class="accordion-collapse collapse" :class="{'show':show}" :data-bs-parent="'#'+parentId">
            <div class="accordion-body w-100">
                <slot></slot>
            </div>
        </div>
    </div>
    
</template>
<script setup>
    const props = defineProps({
        /**
        * DO not provide parent if you don't want other items to collapse
        */
        parentId:{
            type:String
        },title:{
            type:String
        },show:{
            type:Boolean,
            default:false
        },linkClass:{
            type:String,
            required:false
        }
    });
    const id = "id_acordionItem_"+crypto.randomUUID();

</script>
<style scoped>
    .accordion-header{
        flex:0 0 55px;
    }
    .accordion-collapse.show{
        display: flex;
        height:calc(100% - 56px);
    }
    .accordion-body{
        overflow: auto;
        flex:0 0 auto;
    }
    .accordion-collapse{
      
        flex:0 0 auto;
    }
    .accordion-item{
        display: flex;
        flex-direction: column;
    }
    
</style>